import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Carousel03 from '../asset/space03.png'
import Carousel02 from '../asset/space01.png'

function MyCarousel() {
  return (
    <Carousel>
      <Carousel.Item interval={8000}>
        <img
          style={{width: "100%",
            height: "80vh",
            objectFit: "contain",}}
          className="d-block w-100"
          src={Carousel02}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Relax</h3>
          <p>You will get your own way.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <img
          style={{width: "100%",
            height: "80vh",
            objectFit: "contain",}}
          className="d-block w-100"
          src={Carousel03}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3>Relax</h3>
          <p>Live in the present moment because this moment is a present.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default MyCarousel;
