import '../App.css';
import Heading from './Heading';
import RunMotion from './RunMotion';
import ParticleBg from './ParticlesBg';
import MyCarousel from './MyCarousel';

function Home() {
    return (
        <div className="App">
            <div className="App-header minimized-header">
                <ParticleBg />
            </div>
            <div className='body'>    
                <MyCarousel />
            </div>
        </div>
    )
}

export default Home;

//                <Heading content="Welcome!" />
//                <RunMotion line01="Practice in Web Development" line02="Crafted by REACT.JS" />
