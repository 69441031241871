import { motion } from "framer-motion";

function RunMotion(props) {
    return (
        <motion.div
            initial={{y:50, opacity: 0.5}}
            animate={{y:1, opacity: 1 }}
            transition={{ type: 'string', delay: 0.1, duration: 2}}
            whileHover={{
                scale:1.2,
                textShadow: "2px 2px 2px rgb(0,0,0)",
            }}
            whileDrag={{scale: 1}}
        >
             <motion.h2 animate={{color: "white"}}>{props.line01}<br></br>{props.line02}</motion.h2>
        </motion.div>
    )
}

export default RunMotion;